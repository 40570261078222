
























import { Component } from 'vue-property-decorator'

import { Inject } from '../../../../support'

import { AbstractDrawer } from '../../../shared/organisms/AbstractDrawer'

import { NavTabsItemProps } from '../../../../dsl/molecules/NavTabs'

import { IAuthControl, AuthControlServiceType } from '../../contracts'
import { LoginForm } from '../LoginForm'
import { RequestResetLinkForm } from '../RequestResetLinkForm'
import { SignupForm } from '../SignupForm'
import { AuthDrawerTab } from './AuthDrawer.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component({ name: 'AuthDrawer', components: { LoginForm, RequestResetLinkForm, SignupForm } })
export class AuthDrawer extends AbstractDrawer {
  @Inject(AuthControlServiceType)
  protected readonly authControlService!: IAuthControl

  public FORGOT_PASSWORD_TAB_ID = AuthDrawerTab.ForgotPassword
  public LOGIN_TAB_ID = AuthDrawerTab.Login
  public SIGNUP_TAB_ID = AuthDrawerTab.Signup

  public activeTab: string = this.payload.activeTab ?? this.LOGIN_TAB_ID

  public get hasNavs (): boolean {
    return this.activeTab !== this.FORGOT_PASSWORD_TAB_ID
  }

  public get tabItems (): NavTabsItemProps[] {
    return [
      {
        id: this.LOGIN_TAB_ID,
        label: String(this.$t('front.auth.authDrawer.tabs.login.title')),
        active: true
      },
      {
        id: this.SIGNUP_TAB_ID,
        label: String(this.$t('front.auth.authDrawer.tabs.signup.title')),
        active: false
      }
    ]
  }

  public goToForgotPasswordScreen () {
    this.activeTab = this.FORGOT_PASSWORD_TAB_ID
  }

  public goToLoginScreen () {
    this.activeTab = this.LOGIN_TAB_ID
  }

  /**
   * Checks if given tab is active
   * @param id - ID of tab.
   */
  public isActive (id: string): boolean {
    return this.activeTab === id
  }

  public onSuccess (): void {
    this.close(true)
  }
}

export default AuthDrawer
